import { ref, ssrRef, useContext } from '@nuxtjs/composition-api';
import { Faq, FaqsParams } from '@webplatform/cms-js-sdk';

export default (slug: string, params?: FaqsParams) => {
  const {
    $api: { faqsService },
  } = useContext();
  const faqItem = ref<Faq>();
  const pending = ref(false);
  const error = ssrRef(false, 'error');

  const fetchFaqItem = async () => {
    error.value = false;
    pending.value = true;

    try {
      const response = await faqsService.getFaqBySlug(slug, params ?? {});
      faqItem.value = response?.data;
    } catch (e) {
      error.value = true;
    } finally {
      pending.value = false;
    }
  };

  return {
    faqItem,
    pending,
    error,
    fetchFaqItem,
  };
};
